export default function Contact() {

    return (
        <div>
            <p>You can find my work on <a href="https://github.com/gwillad">github</a>. 
                You can contact me on discord at gwillad#0001. 
                If you really really want to, you can also <a href="mailto:adamgwilliam@gmail.com">email me</a>.
            </p>

        </div>
    );
}